@font-face {
    font-family: 'jane-austen';
    src: url('../fonts/JaneAust.ttf') format('truetype');
    /* Add more src with other font formats if available */
}
  
@font-face {
    font-family: 'Afterglow-Regular';
    src: url('../fonts/Afterglow-Regular.ttf') format('truetype');
    /* Add more src with other font formats if available */
}

@font-face {
    font-family: 'Gabriola';
    src: url('../fonts/Gabriola.ttf') format('truetype');
    /* Add more src with other font formats if available */
}

@font-face {
    font-family: 'Lucian-CAT';
    src: url('../fonts/Lucian\ Schoenschrift\ CAT.ttf') format('truetype');
    /* Add more src with other font formats if available */
}

@font-face {
    font-family: 'Hurme-Geometric-Sans1';
    src: url('../fonts/HurmeGeometricSansFamily/HurmeGeometricSans1-SemiBold.otf') format('truetype');
    /* Add more src with other font formats if available */
}

@font-face {
    font-family: 'Chalisa Octavia';
    src: url('../fonts/chalisa_oktavia/Chalisa\ Octavia\ Font_D\ by\ 7NTypes.otf') format('truetype');
    /* Add more src with other font formats if available */
}

@font-face {
    font-family: 'Century Gothic Bold';
    src: url('../fonts/nu_century_gothic.ttf') format('truetype');
    /* Add more src with other font formats if available */
}

@font-face {
    font-family: 'Century Gothic Regular';
    src: url('../fonts/SansSerif/SansSerifBookFLF.otf') format('truetype');
    /* Add more src with other font formats if available */
}