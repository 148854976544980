.coming-soon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: no-repeat center center/cover;
    text-align: center;
    color: white;
  }
  
  .coming-soon-content {
    max-width: 600px;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
  }
  
  .logo {
    width: 150px;
    margin-bottom: 20px;
  }
  
  .app-title {
    font-size: 32px;
    margin-bottom: 20px;
  }
  
  .title {
    font-size: 48px;
    margin-bottom: 20px;
  }
  
  .subtitle {
    font-size: 24px;
  }