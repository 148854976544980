/* ImageZoom.css */
.image-zoom-container {
    overflow: hidden;
    position: relative;
    /* width: 300px; Adjust the container width */
    /* height: 300px; Adjust the container height */
  }
  
  .image-zoom {
    transition: transform 0.5s ease;
  }
  
  .image-zoom:hover {
    transform: scale(1.5); /* Adjust the scale for the zoom effect */
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }
  